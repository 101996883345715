export const PARTNER_TYPE_ADD_REQUEST = "PARTNER_TYPE_ADD_REQUEST";
export const PARTNER_TYPE_ADD_SUCCESS = "PARTNER_TYPE_ADD_SUCCESS";
export const PARTNER_TYPE_ADD_FAIL = "PARTNER_TYPE_ADD_FAIL";

export const ACTIVE_PARTNER_SHOW_REQUEST = "ACTIVE_PARTNER_SHOW_REQUEST";
export const ACTIVE_PARTNER_SHOW_SUCCESS = "ACTIVE_PARTNER_SHOW_SUCCESS";
export const ACTIVE_PARTNER_SHOW_FAIL = "ACTIVE_PARTNER_SHOW_FAIL";

export const USER_CHAT_WITH_PARTNER_POST_REQUEST =
  "USER_CHAT_WITH_PARTNER_POST_REQUEST";
export const USER_CHAT_WITH_PARTNER_POST_SUCCESS =
  "USER_CHAT_WITH_PARTNER_POST_SUCCESS";
export const USER_CHAT_WITH_PARTNER_POST_FAIL =
  "USER_CHAT_WITH_PARTNER_POST_FAIL";

export const USER_CHAT_WITHPARTNER_LIST_REQUEST =
  "USER_CHAT_WITHPARTNER_LIST_REQUEST";
export const USER_CHAT_WITHPARTNER_LIST_SUCCESS =
  "USER_CHAT_WITHPARTNER_LIST_SUCCESS";
export const USER_CHAT_WITHPARTNER_LIST_FAIL =
  "USER_CHAT_WITHPARTNER_LIST_FAIL";

export const OVERALL_CHAT_COUNT_REQUEST = "OVERALL_CHAT_COUNT_REQUEST";
export const OVERALL_CHAT_COUNT_SUCCESS = "OVERALL_CHAT_COUNT_SUCCESS";
export const OVERALL_CHAT_COUNT_FAIL = "OVERALL_CHAT_COUNT_FAIL";

export const CHAT_SEEN_REQUEST = "CHAT_SEEN_REQUEST";
export const CHAT_SEEN_SUCCESS = "CHAT_SEEN_SUCCESS";
export const CHAT_SEEN_FAIL = "CHAT_SEEN_FAIL";

export const PARTNER_SEARCH_FILTER_REQUEST = "PARTNER_SEARCH_FILTER_REQUEST";
export const PARTNER_SEARCH_FILTER_SUCCESS = "PARTNER_SEARCH_FILTER_SUCCESS";
export const PARTNER_SEARCH_FILTER_FAIL = "PARTNER_SEARCH_FILTER_FAIL";

export const PARTNER_EVENTS_POST_REQUEST = "PARTNER_EVENTS_POST_REQUEST";
export const PARTNER_EVENTS_POST_SUCCESS = "PARTNER_EVENTS_POST_SUCCESS";
export const PARTNER_EVENTS_POST_FAIL = "PARTNER_EVENTS_POST_FAIL";

export const PARTNER_EVENTS_VENUE_GET_REQUEST =
  "PARTNER_EVENTS_VENUE_GET_REQUEST";
export const PARTNER_EVENTS_VENUE_GET_SUCCESS =
  "PARTNER_EVENTS_VENUE_GET_SUCCESS";
export const PARTNER_EVENTS_VENUE_GET_FAIL = "PARTNER_EVENTS_VENUE_GET_FAIL";

export const PARTNER_EVENTS_UPDATE_REQUEST = "PARTNER_EVENTS_UPDATE_REQUEST";
export const PARTNER_EVENTS_UPDATE_SUCCESS = "PARTNER_EVENTS_UPDATE_SUCCESS";
export const PARTNER_EVENTS_UPDATE_FAIL = "PARTNER_EVENTS_UPDATE_FAIL";

export const PARTNER_EVENTS_DELETE_REQUEST = "PARTNER_EVENTS_DELETE_REQUEST";
export const PARTNER_EVENTS_DELETE_SUCCESS = "PARTNER_EVENTS_DELETE_SUCCESS";
export const PARTNER_EVENTS_DELETE_FAIL = "PARTNER_EVENTS_DELETE_FAIL";

export const PARTNER_PROFILE_UPDATE_REQUEST = "PARTNER_PROFILE_UPDATE_REQUEST";
export const PARTNER_PROFILE_UPDATE_SUCCESS = "PARTNER_PROFILE_UPDATE_SUCCESS";
export const PARTNER_PROFILE_UPDATE_FAIL = "PARTNER_PROFILE_UPDATE_FAIL";

export const PARTNER_PROFILE_GET_DATA_REQUEST =
  "PARTNER_PROFILE_GET_DATA_REQUEST";
export const PARTNER_PROFILE_GET_DATA_SUCCESS =
  "PARTNER_PROFILE_GET_DATA_SUCCESS";
export const PARTNER_PROFILE_GET_DATA_FAIL = "PARTNER_PROFILE_GET_DATA_FAIL";

export const PARTNER_VENUE_GET_BYID_REQUEST = "PARTNER_VENUE_GET_BYID_REQUEST";
export const PARTNER_VENUE_GET_BYID_SUCCESS = "PARTNER_VENUE_GET_BYID_SUCCESS";
export const PARTNER_VENUE_GET_BYID_FAIL = "PARTNER_VENUE_GET_BYID_FAIL";

export const PLAT_FORM_SUGGESTION_REQUEST = "PLAT_FORM_SUGGESTION_REQUEST";
export const PLAT_FORM_SUGGESTION_SUCCESS = "PLAT_FORM_SUGGESTION_SUCCESS";
export const PLAT_FORM_SUGGESTION_FAIL = "PLAT_FORM_SUGGESTION_FAIL";

export const PLAT_FORM_SUGGESTION_GET_DATA_REQUEST =
  "PLAT_FORM_SUGGESTION_GET_DATA_REQUEST";
export const PLAT_FORM_SUGGESTION_GET_DATA_SUCCESS =
  "PLAT_FORM_SUGGESTION_GET_DATA_SUCCESS";
export const PLAT_FORM_SUGGESTION_GET_DATA_FAIL =
  "PLAT_FORM_SUGGESTION_GET_DATA_FAIL";

export const ADMIN_DELETE_PARTNER_SUGGESTION_REQUEST =
  "ADMIN_DELETE_PARTNER_SUGGESTION_REQUEST";
export const ADMIN_DELETE_PARTNER_SUGGESTION_SUCCESS =
  "ADMIN_DELETE_PARTNER_SUGGESTION_SUCCESS";
export const ADMIN_DELETE_PARTNER_SUGGESTION_FAIL =
  "ADMIN_DELETE_PARTNER_SUGGESTION_FAIL";

export const PARTNER_SUGGESTION_GETBYID_REQUEST =
  "PARTNER_SUGGESTION_GETBYID_REQUEST";
export const PARTNER_SUGGESTION_GETBYID_SUCCESS =
  "PARTNER_SUGGESTION_GETBYID_SUCCESS";
export const PARTNER_SUGGESTION_GETBYID_FAIL =
  "PARTNER_SUGGESTION_GETBYID_FAIL";

export const SUPPORT_CHAT_POST_REQUEST = "SUPPORT_CHAT_POST_REQUEST";
export const SUPPORT_CHAT_POST_SUCCESS = "SUPPORT_CHAT_POST_SUCCESS";
export const SUPPORT_CHAT_POST_FAIL = "SUPPORT_CHAT_POST_FAIL";

export const SUPPORT_CHAT_GET_REQUEST = "SUPPORT_CHAT_GET_REQUEST";
export const SUPPORT_CHAT_GET_SUCCESS = "SUPPORT_CHAT_GET_SUCCESS";
export const SUPPORT_CHAT_GET_FAIL = "SUPPORT_CHAT_GET_FAIL";

// USER CHAT LIST ADD IN FUTURE

export const SUPPORT_CHAT_REQUEST_TO_ADMIN_MESSGAE_REQUEST =
  "SUPPORT_CHAT_REQUEST_TO_ADMIN_MESSGAE_REQUEST";
export const SUPPORT_CHAT_REQUEST_TO_ADMIN_MESSGAE_SUCCESS =
  "SUPPORT_CHAT_REQUEST_TO_ADMIN_MESSGAE_SUCCESS";
export const SUPPORT_CHAT_REQUEST_TO_ADMIN_MESSGAE_FAIL =
  "SUPPORT_CHAT_REQUEST_TO_ADMIN_MESSGAE_FAIL";

export const PARTNER_ADD_USER_REQUEST = "PARTNER_ADD_USER_REQUEST";
export const PARTNER_ADD_USER_SUCCESS = "PARTNER_ADD_USER_SUCCESS";
export const PARTNER_ADD_USER_FAIL = "PARTNER_ADD_USER_FAIL";

export const PARTNER_SEE_HIS_USER_LIST_REQUEST =
  "PARTNER_SEE_HIS_USER_LIST_REQUEST";
export const PARTNER_SEE_HIS_USER_LIST_SUCCESS =
  "PARTNER_SEE_HIS_USER_LIST_SUCCESS";
export const PARTNER_SEE_HIS_USER_LIST_FAIL = "PARTNER_SEE_HIS_USER_LIST_FAIL";

export const USER_HELP_LIST_ALLMESSAGE_REQUEST =
  "USER_HELP_LIST_ALLMESSAGE_REQUEST";
export const USER_HELP_LIST_ALLMESSAGE_SUCCESS =
  "USER_HELP_LIST_ALLMESSAGE_SUCCESS";
export const USER_HELP_LIST_ALLMESSAGE_FAIL = "USER_HELP_LIST_ALLMESSAGE_FAIL";

export const USER_CHAT_GETBY_ID_REQUEST = "USER_CHAT_GETBY_ID_REQUEST";
export const USER_CHAT_GETBY_ID_SUCCESS = "USER_CHAT_GETBY_ID_SUCCESS";
export const USER_CHAT_GETBY_ID_FAIL = "USER_CHAT_GETBY_ID_FAIL";

export const PARTNERS_OWN_USERS_REQUEST = "PARTNERS_OWN_USERS_REQUEST";
export const PARTNERS_OWN_USERS_SUCCESS = "PARTNERS_OWN_USERS_SUCCESS";
export const PARTNERS_OWN_USERS_FAIL = "PARTNERS_OWN_USERS_FAIL";

export const PARTNER_ADD_TUTORIAL_REQUEST = "PARTNER_ADD_TUTORIAL_REQUEST";
export const PARTNER_ADD_TUTORIAL_SUCCESS = "PARTNER_ADD_TUTORIAL_SUCCESS";
export const PARTNER_ADD_TUTORIAL_FAIL = "PARTNER_ADD_TUTORIAL_FAIL";

export const PARTNER_TUTORIAL_LIST_REQUEST = "PARTNER_TUTORIAL_LIST_REQUEST";
export const PARTNER_TUTORIAL_LIST_SUCCESS = "PARTNER_TUTORIAL_LIST_SUCCESS";
export const PARTNER_TUTORIAL_LIST_FAIL = "PARTNER_TUTORIAL_LIST_FAIL";

export const PARTNER_TUTORIAL_DELETE_REQUEST =
  "PARTNER_TUTORIAL_DELETE_REQUEST";
export const PARTNER_TUTORIAL_DELETE_SUCCESS =
  "PARTNER_TUTORIAL_DELETE_SUCCESS";
export const PARTNER_TUTORIAL_DELETE_FAIL = "PARTNER_TUTORIAL_DELETE_FAIL";

export const PARTNER_TUTORIAL_GET_DATABYID_REQUEST =
  "PARTNER_TUTORIAL_GET_DATABYID_REQUEST";
export const PARTNER_TUTORIAL_GET_DATABYID_SUCCESS =
  "PARTNER_TUTORIAL_GET_DATABYID_SUCCESS";
export const PARTNER_TUTORIAL_GET_DATABYID_FAIL =
  "PARTNER_TUTORIAL_GET_DATABYID_FAIL";

export const PARTNER_TUTORIAL_UPDATE_REQUEST =
  "PARTNER_TUTORIAL_UPDATE_REQUEST";
export const PARTNER_TUTORIAL_UPDATE_SUCCESS =
  "PARTNER_TUTORIAL_UPDATE_SUCCESS";
export const PARTNER_TUTORIAL_UPDATE_FAIL = "PARTNER_TUTORIAL_UPDATE_FAIL";

export const IS_VERIFIED_VEDIO_SEE_USER_REQUEST =
  "IS_VERIFIED_VEDIO_SEE_USER_REQUEST";
export const IS_VERIFIED_VEDIO_SEE_USER_SUCCESS =
  "IS_VERIFIED_VEDIO_SEE_USER_SUCCESS";
export const IS_VERIFIED_VEDIO_SEE_USER_FAIL =
  "IS_VERIFIED_VEDIO_SEE_USER_FAIL";

export const IS_VERIFIED_LIST_USER_REQUEST = "IS_VERIFIED_LIST_USER_REQUEST";
export const IS_VERIFIED_LIST_USER_SUCCESS = "IS_VERIFIED_LIST_USER_SUCCESS";
export const IS_VERIFIED_LIST_USER_FAIL = "IS_VERIFIED_LIST_USER_FAIL";

export const PARTNER_ADD_PRODUCT_REQUEST = "PARTNER_ADD_PRODUCT_REQUEST";
export const PARTNER_ADD_PRODUCT_SUCCESS = "PARTNER_ADD_PRODUCT_SUCCESS";
export const PARTNER_ADD_PRODUCT_FAIL = "PARTNER_ADD_PRODUCT_FAIL";

export const PARTNER_PRODUCT_LIST_REQUEST = "PARTNER_PRODUCT_LIST_REQUEST";
export const PARTNER_PRODUCT_LIST_SUCCESS = "PARTNER_PRODUCT_LIST_SUCCESS";
export const PARTNER_PRODUCT_LIST_FAIL = "PARTNER_PRODUCT_LIST_FAIL";

export const PARTNER_PRODUCT_DELETE_REQUEST = "PARTNER_PRODUCT_DELETE_REQUEST";
export const PARTNER_PRODUCT_DELETE_SUCCESS = "PARTNER_PRODUCT_DELETE_SUCCESS";
export const PARTNER_PRODUCT_DELETE_FAIL = "PARTNER_PRODUCT_DELETE_FAIL";

export const PARTNER_PRODUCT_GET_DATA_REQUEST =
  "PARTNER_PRODUCT_GET_DATA_REQUEST";
export const PARTNER_PRODUCT_GET_DATA_SUCCESS =
  "PARTNER_PRODUCT_GET_DATA_SUCCESS";
export const PARTNER_PRODUCT_GET_DATA_FAIL = "PARTNER_PRODUCT_GET_DATA_FAIL";

export const PARTNER_PRODUCT_UPDATE_REQUEST = "PARTNER_PRODUCT_UPDATE_REQUEST";
export const PARTNER_PRODUCT_UPDATE_SUCCESS = "PARTNER_PRODUCT_UPDATE_SUCCESS";
export const PARTNER_PRODUCT_UPDATE_FAIL = "PARTNER_PRODUCT_UPDATE_FAIL";

export const GET_ALL_CHAT_WITH_USER_REQUEST = "GET_ALL_CHAT_WITH_USER_REQUEST";
export const GET_ALL_CHAT_WITH_USER_SUCCESS = "GET_ALL_CHAT_WITH_USER_SUCCESS";
export const GET_ALL_CHAT_WITH_USER_FAIL = "GET_ALL_CHAT_WITH_USER_FAIL";

export const USER_MESSAGE_CHAT_SELECT_REQUEST =
  "USER_MESSAGE_CHAT_SELECT_REQUEST";
export const USER_MESSAGE_CHAT_SELECT_SUCCESS =
  "USER_MESSAGE_CHAT_SELECT_SUCCESS";
export const USER_MESSAGE_CHAT_SELECT_FAIL = "USER_MESSAGE_CHAT_SELECT_FAIL";

export const GET_ALL_USER_DATA_BYID_REQUEST = "GET_ALL_USER_DATA_BYID_REQUEST";
export const GET_ALL_USER_DATA_BYID_SUCCESS = "GET_ALL_USER_DATA_BYID_SUCCESS";
export const GET_ALL_USER_DATA_BYID_FAIL = "GET_ALL_USER_DATA_BYID_FAIL";

// sachin

export const GET_HIRED_PERSON_LIST_REQUEST = "GET_HIRED_PERSON_LIST_REQUEST";
export const GET_HIRED_PERSON_LIST_SUCCESS = "GET_HIRED_PERSON_LIST_SUCCESS";
export const GET_HIRED_PERSON_LIST_FAIL = "GET_HIRED_PERSON_LIST_FAIL";

export const GET_ALL_PERSON_LIST_REQUEST = "GET_ALL_PERSON_LIST_REQUEST";
export const GET_ALL_PERSON_LIST_SUCCESS = "GET_ALL_PERSON_LIST_SUCCESS";
export const GET_ALL_PERSON_LIST_FAIL = "GET_ALL_PERSON_LIST_FAIL";

export const GET_HIRE_PERSON_DETAILS_REQUEST =
  "GET_HIRE_PERSON_DETAILS_REQUEST";
export const GET_HIRE_PERSON_DETAILS_SUCCESS =
  "GET_HIRE_PERSON_DETAILS_SUCCESS";
export const GET_HIRE_PERSON_DETAILS_FAIL = "GET_HIRE_PERSON_DETAILS_FAIL";

export const MY_VIDEO_ADD_REQUEST = "MY_VIDEO_ADD_REQUEST";
export const MY_VIDEO_ADD_SUCCESS = "MY_VIDEO_ADD_SUCCESS";
export const MY_VIDEO_ADD_FAIL = "MY_VIDEO_ADD_FAIL";

export const MY_VIDEO_ALL_LIST_REQUEST = "MY_VIDEO_ALL_LIST_REQUEST";
export const MY_VIDEO_ALL_LIST_SUCCESS = "MY_VIDEO_ALL_LIST_SUCCESS";
export const MY_VIDEO_ALL_LIST_FAIL = "MY_VIDEO_ALL_LIST_FAIL";

export const MY_VIDEO_EDIT_REQUEST = "MY_VIDEO_EDIT_REQUEST";
export const MY_VIDEO_EDIT_SUCCESS = "MY_VIDEO_EDIT_SUCCESS";
export const MY_VIDEO_EDIT_FAIL = "MY_VIDEO_EDIT_FAIL";

export const MY_VIDEO_DELETE_REQUEST = "MY_VIDEO_DELETE_REQUEST";
export const MY_VIDEO_DELETE_SUCCESS = "MY_VIDEO_DELETE_SUCCESS";
export const MY_VIDEO_DELETE_FAIL = "MY_VIDEO_DELETE_FAIL";

export const MY_VIDEO_GET_DATA_BYID_REQUEST = "MY_VIDEO_GET_DATA_BYID_REQUEST";
export const MY_VIDEO_GET_DATA_BYID_SUCCESS = "MY_VIDEO_GET_DATA_BYID_SUCCESS";
export const MY_VIDEO_GET_DATA_BYID_FAIL = "MY_VIDEO_GET_DATA_BYID_FAIL";

export const MY_USER_SHOW_PARTNER_LOGIN_REQUEST =
  "MY_USER_SHOW_PARTNER_LOGIN_REQUEST";
export const MY_USER_SHOW_PARTNER_LOGIN_SUCCESS =
  "MY_USER_SHOW_PARTNER_LOGIN_SUCCESS";
export const MY_USER_SHOW_PARTNER_LOGIN_FAIL =
  "MY_USER_SHOW_PARTNER_LOGIN_FAIL";
