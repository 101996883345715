import React from "react";
import { Frontend_URL } from "../../environment";

const Parnter_Vender_profile = () => {
  return (
    <div>
      <body class="font-sans">
        <div class="bg-white">
          <div class="bg-black mb-6">
            <div class="container mx-auto px-8">
              <div class="flex items-center py-2 px-10">
                <div class="w-3/4 flex items-center">
                  <div>
                    <svg
                      class="h-8 w-8 fill-current text-white"
                      aria-labelledby="simpleicons-github-icon"
                      role="img"
                      viewBox="0 0 24 24"
                    >
                      <title id="simpleicons-github-icon">GitHub icon</title>
                      <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
                    </svg>
                  </div>
                  <div class="ml-4">
                    <input
                      class="p-2 rounded bg-grey-darkest text-grey-light text-xs w-128"
                      type="text"
                      name="q"
                      placeholder="Search Github"
                    />
                  </div>
                  <div class="ml-4">
                    <div class="text-sm flex items-center">
                      <div>
                        <a href="#" class="no-underline text-grey-light pr-3">
                          Pull Requests
                        </a>
                      </div>
                      <div>
                        <a href="#" class="no-underline text-grey-light pr-3">
                          Issues
                        </a>
                      </div>
                      <div>
                        <a href="#" class="no-underline text-grey-light pr-3">
                          Marketplace
                        </a>
                      </div>
                      <div>
                        <a href="#" class="no-underline text-grey-light pr-3">
                          Explore
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-1/4 text-right flex items-center justify-end">
                  <div class="flex items-center mr-3">
                    <div>
                      <svg
                        class="fill-current text-grey-light h-4 w-4"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z" />
                      </svg>
                    </div>
                  </div>
                  <div class="flex items-center mr-3">
                    <div>
                      <svg
                        class="fill-current text-grey-light h-4 w-4"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                      </svg>
                    </div>
                    <div class="">
                      <svg
                        class="fill-current text-grey-light h-4 w-4"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
                      </svg>
                    </div>
                  </div>
                  <div class="flex items-center">
                    <div>
                      <img
                        class="h-4 w-4 inline-block rounded"
                        src="https://avatars2.githubusercontent.com/u/1791228?s=460&v=4"
                      />
                    </div>
                    <div class="">
                      <svg
                        class="fill-current text-grey-light h-4 w-4"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex ">
            <div class="container mx-auto px-8">
              <div class="flex px-10">
                <div class="w-1/4">
                  <div class="mb-4">
                    <img
                      class="rounded-lg"
                      src="https://avatars2.githubusercontent.com/u/1791228?s=460&v=4"
                    />
                  </div>
                  <div class="mb-4">
                    <div class="text-3xl font-medium text-grey-darkest">
                      Andrew Huggins
                    </div>
                    <div class="text-xl text-grey-dark font-light">
                      ahuggins
                    </div>
                  </div>
                  <div class="pb-6 border-b">
                    <a href="#" class="no-underline text-sm ">
                      Add a bio
                    </a>
                  </div>
                  <div class="flex items-center text-left py-4 mb-4 border-b">
                    <div class="flex items-center mr-2">
                      <svg
                        class="fill-current text-grey-dark w-4 h-4"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z" />
                      </svg>
                    </div>
                    <div>
                      <a href="#" class="no-underline text-sm">
                        email@address.com
                      </a>
                    </div>
                  </div>
                  <div class="">
                    <div class="font-medium text-grey-darkest">
                      Organizations
                    </div>
                    <div class="flex items-center mt-2">
                      <div class="mr-1">
                        <a href="https://github.com/cogitools">
                          <img
                            class="w-8 h-8 rounded"
                            src="https://avatars1.githubusercontent.com/u/1054277?s=70&v=4"
                          />
                        </a>
                      </div>
                      <div class="mr-1">
                        <a href="https://github.com/cogitools">
                          <img
                            class="w-8 h-8 rounded"
                            src="https://avatars1.githubusercontent.com/u/1054277?s=70&v=4"
                          />
                        </a>
                      </div>
                      <div class="mr-1">
                        <a href="https://github.com/cogitools">
                          <img
                            class="w-8 h-8 rounded"
                            src="https://avatars1.githubusercontent.com/u/1054277?s=70&v=4"
                          />
                        </a>
                      </div>
                      <div>
                        <a href="https://github.com/cogitools">
                          <img
                            class="w-8 h-8 rounded"
                            src="https://avatars1.githubusercontent.com/u/1054277?s=70&v=4"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-3/4 ml-6 mt-2">
                  <div class="flex items-center font-thin text-grey-dark text-sm border-b">
                    <div class="p-4 border-b-2 font-normal text-grey-darkest border-orange -mb-2px">
                      Overview
                    </div>
                    <div class="p-4 flex items-center">
                      <div>Repositories</div>
                      <div class="rounded-lg bg-grey-light text-xs ml-1 p-px">
                        41
                      </div>
                    </div>
                    <div class="p-4 flex items-center">
                      <div>Stars</div>
                      <div class="rounded-lg bg-grey-light text-xs ml-1 p-px">
                        6
                      </div>
                    </div>
                    <div class="p-4 flex items-center">
                      <div>Followers</div>
                      <div class="rounded-lg bg-grey-light text-xs ml-1 p-px">
                        15
                      </div>
                    </div>
                    <div class="p-4 flex items-center">
                      <div>Following</div>
                      <div class="rounded-lg bg-grey-light text-xs ml-1 p-px">
                        23
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center">
                    <div class="w-1/2 pt-6 pb-2 font-normal text-grey-darkest">
                      Pinned repositories
                    </div>
                    <div class="w-1/2 justify-end text-right text-grey-dark text-sm font-light pt-6 pb-2">
                      Customize your pinned repositories
                    </div>
                  </div>
                  <div class="flex">
                    <div
                      class="w-1/2 border px-4 py-4 mb-4
    -mr-2
rounded text-sm"
                    >
                      <div class="flex">
                        <div class="mr-2">
                          <svg
                            class="h-3 w-3 fill-current text-grey-dark"
                            viewBox="0 0 32 32"
                          >
                            <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
                          </svg>
                        </div>
                        <div>
                          <a href="#" class="no-underline">
                            laravel/framework
                          </a>
                        </div>
                      </div>
                      <div class="flex text-sm mt-6">
                        <div class="mr-4 font-thin text-xs">PHP</div>
                        <div class="mr-4 font-thin text-xs">8.8k</div>
                        <div class="mr-4 font-thin text-xs">5k</div>
                      </div>
                    </div>
                    <div
                      class="w-1/2 border px-4 py-4 mb-4
ml-4
rounded text-sm"
                    >
                      <div class="flex">
                        <div class="mr-2">
                          <svg
                            class="h-3 w-3 fill-current text-grey-dark"
                            viewBox="0 0 32 32"
                          >
                            <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
                          </svg>
                        </div>
                        <div>
                          <a href="#" class="no-underline">
                            laravel/framework
                          </a>
                        </div>
                      </div>
                      <div class="flex text-sm mt-6">
                        <div class="mr-4 font-thin text-xs">PHP</div>
                        <div class="mr-4 font-thin text-xs">8.8k</div>
                        <div class="mr-4 font-thin text-xs">5k</div>
                      </div>
                    </div>
                  </div>
                  <div class="flex">
                    <div
                      class="w-1/2 border px-4 py-4 mb-4
    -mr-2
rounded text-sm"
                    >
                      <div class="flex">
                        <div class="mr-2">
                          <svg
                            class="h-3 w-3 fill-current text-grey-dark"
                            viewBox="0 0 32 32"
                          >
                            <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
                          </svg>
                        </div>
                        <div>
                          <a href="#" class="no-underline">
                            laravel/framework
                          </a>
                        </div>
                      </div>
                      <div class="flex text-sm mt-6">
                        <div class="mr-4 font-thin text-xs">PHP</div>
                        <div class="mr-4 font-thin text-xs">8.8k</div>
                        <div class="mr-4 font-thin text-xs">5k</div>
                      </div>
                    </div>
                    <div
                      class="w-1/2 border px-4 py-4 mb-4
ml-4
rounded text-sm"
                    >
                      <div class="flex">
                        <div class="mr-2">
                          <svg
                            class="h-3 w-3 fill-current text-grey-dark"
                            viewBox="0 0 32 32"
                          >
                            <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
                          </svg>
                        </div>
                        <div>
                          <a href="#" class="no-underline">
                            laravel/framework
                          </a>
                        </div>
                      </div>
                      <div class="flex text-sm mt-6">
                        <div class="mr-4 font-thin text-xs">PHP</div>
                        <div class="mr-4 font-thin text-xs">8.8k</div>
                        <div class="mr-4 font-thin text-xs">5k</div>
                      </div>
                    </div>
                  </div>
                  <div class="flex">
                    <div
                      class="w-1/2 border px-4 py-4 mb-4
    -mr-2
rounded text-sm"
                    >
                      <div class="flex">
                        <div class="mr-2">
                          <svg
                            class="h-3 w-3 fill-current text-grey-dark"
                            viewBox="0 0 32 32"
                          >
                            <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
                          </svg>
                        </div>
                        <div>
                          <a href="#" class="no-underline">
                            laravel/framework
                          </a>
                        </div>
                      </div>
                      <div class="flex text-sm mt-6">
                        <div class="mr-4 font-thin text-xs">PHP</div>
                        <div class="mr-4 font-thin text-xs">8.8k</div>
                        <div class="mr-4 font-thin text-xs">5k</div>
                      </div>
                    </div>
                    <div
                      class="w-1/2 border px-4 py-4 mb-4
ml-4
rounded text-sm"
                    >
                      <div class="flex">
                        <div class="mr-2">
                          <svg
                            class="h-3 w-3 fill-current text-grey-dark"
                            viewBox="0 0 32 32"
                          >
                            <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
                          </svg>
                        </div>
                        <div>
                          <a href="#" class="no-underline">
                            laravel/framework
                          </a>
                        </div>
                      </div>
                      <div class="flex text-sm mt-6">
                        <div class="mr-4 font-thin text-xs">PHP</div>
                        <div class="mr-4 font-thin text-xs">8.8k</div>
                        <div class="mr-4 font-thin text-xs">5k</div>
                      </div>
                    </div>
                  </div>

                  <div class="flex items-center">
                    <div class="w-1/2 pt-6 pb-2 font-normal text-grey-darkest">
                      1,438 contributions in the last year
                    </div>
                    <div class="w-1/2 pt-6 pb-2 justify-end text-right text-grey-dark text-sm font-light flex">
                      <div>Contribution settings</div>
                      <div class="">
                        <svg
                          class="fill-current text-grey-dark h-4 w-4"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="border p-4 rounded">
                    <img src="https://user-images.githubusercontent.com/1791228/34055423-1e71e608-e19d-11e7-87f9-49d2cbb08c50.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-8">
            <div class="container mx-auto px-8">
              <div class="px-10">
                <div class="flex items-center justify-between border-t py-8">
                  <div class="justify-start justify-between text-xs w-2/5 flex">
                    <div class="mr-4">&copy; 2017 GitHub, Inc.</div>
                    <div class="mr-4">
                      <a href="#" class="no-underline">
                        Terms
                      </a>
                    </div>
                    <div class="mr-4">
                      <a href="#" class="no-underline">
                        Privacy
                      </a>
                    </div>
                    <div class="mr-4">
                      <a href="#" class="no-underline">
                        Security
                      </a>
                    </div>
                    <div class="mr-4">
                      <a href="#" class="no-underline">
                        Status
                      </a>
                    </div>
                    <div class="mr-4">
                      <a href="#" class="no-underline">
                        Help
                      </a>
                    </div>
                  </div>
                  <div class="justify-center w-1/5 text-center">
                    <svg
                      class="fill-current text-grey h-6 w-6"
                      aria-labelledby="simpleicons-github-icon"
                      role="img"
                      viewBox="0 0 24 24"
                    >
                      <title id="simpleicons-github-icon">GitHub icon</title>
                      <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
                    </svg>
                  </div>
                  <div class="justify-end justify-between text-xs w-2/5 flex text-right">
                    <div class="ml-4">
                      <a href="#" class="no-underline">
                        Contact GitHub
                      </a>
                    </div>
                    <div class="ml-4">
                      <a href="#" class="no-underline">
                        API
                      </a>
                    </div>
                    <div class="ml-4">
                      <a href="#" class="no-underline">
                        Training
                      </a>
                    </div>
                    <div class="ml-4">
                      <a href="#" class="no-underline">
                        Shop
                      </a>
                    </div>
                    <div class="ml-4">
                      <a href="#" class="no-underline">
                        Blog
                      </a>
                    </div>
                    <div class="ml-4">
                      <a href="#" class="no-underline">
                        About
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default Parnter_Vender_profile;
