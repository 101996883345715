export const ADMIN_CREATE_PLAN_REQUEST = "ADMIN_CREATE_PLAN_REQUEST";
export const ADMIN_CREATE_PLAN_SUCCESS = "ADMIN_CREATE_PLAN_SUCCESS";
export const ADMIN_CREATE_PLAN_FAIL = "ADMIN_CREATE_PLAN_FAIL";

export const ADMIN_SEE_PLAN_LIST_REQUEST = "ADMIN_SEE_PLAN_LIST_REQUEST";
export const ADMIN_SEE_PLAN_LIST_SUCCESS = "ADMIN_SEE_PLAN_LIST_SUCCESS";
export const ADMIN_SEE_PLAN_LIST_FAIL = "ADMIN_SEE_PLAN_LIST_FAIL";

export const ADMIN_DELETE_PLAN_REQUEST = "ADMIN_DELETE_PLAN_REQUEST";
export const ADMIN_DELETE_PLAN_SUCCESS = "ADMIN_DELETE_PLAN_SUCCESS";
export const ADMIN_DELETE_PLAN_FAIL = "ADMIN_DELETE_PLAN_LIST_FAIL";

export const PURCHASRE_PLAN_REQUEST = "PURCHASRE_PLAN_REQUEST";
export const PURCHASRE_PLAN_SUCCESS = "PURCHASRE_PLAN_SUCCESS";
export const PURCHASRE_PLAN_FAIL = "PURCHASRE_PLAN_LIST_FAIL";

export const SUBSCRIPTION_CHECK_REQUEST = "SUBSCRIPTION_CHECK_REQUEST";
export const SUBSCRIPTION_CHECK_SUCCESS = "SUBSCRIPTION_CHECK_SUCCESS";
export const SUBSCRIPTION_CHECK_FAIL = "SUBSCRIPTION_CHECK_LIST_FAIL";

export const GET_WALLET_BALANCE_REQUEST = "GET_WALLET_BALANCE_REQUEST";
export const GET_WALLET_BALANCE_SUCCESS = "GET_WALLET_BALANCE_SUCCESS";
export const GET_WALLET_BALANCE_FAIL = "GET_WALLET_BALANCE_LIST_FAIL";

export const PAYMENT_OF_CONTRACT_REQUEST = "PAYMENT_OF_CONTRACT_REQUEST";
export const PAYMENT_OF_CONTRACT_SUCCESS = "PAYMENT_OF_CONTRACT_SUCCESS";
export const PAYMENT_OF_CONTRACT_FAIL = "PAYMENT_OF_CONTRACT_LIST_FAIL";

export const GET_SUBCRIPTION_FOR_VENDOR_REQUEST = "GET_SUBCRIPTION_FOR_VENDOR_REQUEST";
export const GET_SUBCRIPTION_FOR_VENDOR_SUCCESS = "GET_SUBCRIPTION_FOR_VENDOR_SUCCESS";
export const GET_SUBCRIPTION_FOR_VENDOR_FAIL = "GET_SUBCRIPTION_FOR_VENDOR_LIST_FAIL";

export const VENDOR_BUY_PLAN_REQUEST = "VENDOR_BUY_PLAN_REQUEST";
export const VENDOR_BUY_PLAN_SUCCESS = "VENDOR_BUY_PLAN_SUCCESS";
export const VENDOR_BUY_PLAN_FAIL = "VENDOR_BUY_PLAN_LIST_FAIL";