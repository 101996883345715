export const ALL_USER_LIST_REQUEST = "ALL_USER_LIST_REQUEST";
export const ALL_USER_LIST_SUCCESS = "ALL_USER_LIST_SUCCESS";
export const ALL_USER_LIST_FAIL = "ALL_USER_LIST_FAIL";

export const NEW_USER_ACTIVE_REQUEST = "NEW_USER_ACTIVE_REQUEST";
export const NEW_USER_ACTIVE_SUCCESS = "NEW_USER_ACTIVE_SUCCESS";
export const NEW_USER_ACTIVE_FAIL = "NEW_USER_ACTIVE_FAIL";

export const PROFILE_UPADTE_REQUEST = "PROFILE_UPADTE_REQUEST";
export const PROFILE_UPADTE_SUCCESS = "PROFILE_UPADTE_SUCCESS";
export const PROFILE_UPADTE_FAIL = "PROFILE_UPADTE_FAIL";

export const PROFILE_DETAIL_GET_REQUEST = "PROFILE_DETAIL_GET_REQUEST";
export const PROFILE_DETAIL_GET_SUCCESS = "PROFILE_DETAIL_GET_SUCCESS";
export const PROFILE_DETAIL_GET_FAIL = "PROFILE_DETAIL_GET_FAIL";

export const PARTNER_REGISTER_REQUEST = "PARTNER_REGISTER_REQUEST";
export const PARTNER_REGISTER_SUCCESS = "PARTNER_REGISTER_SUCCESS";
export const PARTNER_REGISTER_FAIL = "PARTNER_REGISTER_FAIL";

export const PARTNER_TYPE_USER_LIST_REQUEST = "PARTNER_TYPE_USER_LIST_REQUEST";
export const PARTNER_TYPE_USER_LIST_SUCCESS = "PARTNER_TYPE_USER_LIST_SUCCESS";
export const PARTNER_TYPE_USER_LIST_FAIL = "PARTNER_TYPE_USER_LIST_FAIL";

export const PARTNER_PENDING_LIST_REQUEST = "PARTNER_PENDING_LIST_REQUEST";
export const PARTNER_PENDING_LIST_SUCCESS = "PARTNER_PENDING_LIST_SUCCESS";
export const PARTNER_PENDING_LIST_FAIL = "PARTNER_PENDING_LIST_FAIL";

export const PARTNER_REQUEST_ACCEPT_DECLINE_REQUEST =
  "PARTNER_REQUEST_ACCEPT_DECLINE_REQUEST";
export const PARTNER_REQUEST_ACCEPT_DECLINE_SUCCESS =
  "PARTNER_REQUEST_ACCEPT_DECLINE_SUCCESS";
export const PARTNER_REQUEST_ACCEPT_DECLINE_FAIL =
  "PARTNER_REQUEST_ACCEPT_DECLINE_FAIL";

export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAIL = "INVITE_USER_FAIL";

export const INVITE_MEMBER_LIST_REQUEST = "INVITE_MEMBER_LIST_REQUEST";
export const INVITE_MEMBER_LIST_SUCCESS = "INVITE_MEMBER_LIST_SUCCESS";
export const INVITE_MEMBER_LIST_FAIL = "INVITE_MEMBER_LIST_FAIL";

export const INVITE_USER_REQUEST_ACCEPT_DECLINE_REQUEST =
  "INVITE_USER_REQUEST_ACCEPT_DECLINE_REQUEST";
export const INVITE_USER_REQUEST_ACCEPT_DECLINE_SUCCESS =
  "INVITE_USER_REQUEST_ACCEPT_DECLINE_SUCCESS";
export const INVITE_USER_REQUEST_ACCEPT_DECLINE_FAIL =
  "INVITE_USER_REQUEST_ACCEPT_DECLINE_FAIL";

export const ALL_ROLE_GET_REQUEST = "ALL_ROLE_GET_REQUEST";
export const ALL_ROLE_GET_SUCCESS = "ALL_ROLE_GET_SUCCESS";
export const ALL_ROLE_GET_FAIL = "ALL_ROLE_GET_FAIL";

export const CONTACT_US_DETAILS_ADD_REQUEST =
  "  CONTACT_US_DETAILS_ADD_REQUEST";
export const CONTACT_US_DETAILS_ADD_SUCCESS =
  "  CONTACT_US_DETAILS_ADD_SUCCESS";
export const CONTACT_US_DETAILS_ADD_FAIL = " CONTACT_US_DETAILS_ADD_FAIL";

export const CONTACT_US_DETAILS_GET_REQUEST =
  "  CONTACT_US_DETAILS_GET_REQUEST";
export const CONTACT_US_DETAILS_GET_SUCCESS =
  "  CONTACT_US_DETAILS_GET_SUCCESS";
export const CONTACT_US_DETAILS_GET_FAIL = " CONTACT_US_DETAILS_GET_FAIL";

export const USER_CONTACT_ADMIN_REQUEST = "USER_CONTACT_ADMIN_REQUEST";
export const USER_CONTACT_ADMIN_SUCCESS = "USER_CONTACT_ADMIN_SUCCESS";
export const USER_CONTACT_ADMIN_FAIL = "USER_CONTACT_ADMIN_FAIL";

export const USER_UPDATE_PASSWORD_REQUEST = "USER_UPDATE_PASSWORD_REQUEST";
export const USER_UPDATE_PASSWORD_SUCCESS = "USER_UPDATE_PASSWORD_SUCCESS";
export const USER_UPDATE_PASSWORD_FAIL = "USER_UPDATE_PASSWORD_FAIL";

export const REALTIME_NOTIFICATION_REQUEST = "REALTIME_NOTIFICATION_REQUEST";
export const REALTIME_NOTIFICATION_SUCCESS = "REALTIME_NOTIFICATION_SUCCESS";
export const REALTIME_NOTIFICATION_FAIL = "REALTIME_NOTIFICATION_FAIL";

export const REALTIME_NOTIFICATION_UPDATE_REQUEST =
  "REALTIME_NOTIFICATION_UPDATE_REQUEST";
export const REALTIME_NOTIFICATION_UPDATE_SUCCESS =
  "REALTIME_NOTIFICATION_UPDATE_SUCCESS";
export const REALTIME_NOTIFICATION_UPDATE_FAIL =
  "REALTIME_NOTIFICATION_UPDATE_FAIL";



  export const GET_USER_PARTNER_REQUEST = "GET_USER_PARTNER_REQUEST";
  export const GET_USER_PARTNER_SUCCESS = "GET_USER_PARTNER_SUCCESS";
  export const GET_USER_PARTNER_FAIL = "GET_USER_PARTNER_FAIL";
  