import React from "react";
import { Frontend_URL } from "../../environment";

function Terms_Condtion() {
  return (
    <div>
      <div className="main-about-page">
        <div className="about-fixedimage">
          <div
            className="main-containerabout"
            style={{
              backgroundImage: `url(${Frontend_URL}img/beautiful-church-.jpg)`,
              backgroundAttachment: "fixed",
            }}
          >
            <div className="bgimage-contnet">
              <div className="container p-3  mx-auto ">
                <div className="toptextabout">
                  <div className="image-textcontnet">
                    <div className="center-sectioncontnet">
                      <p className="merorialtext">Memorial Information</p>
                      <h1 className="letus-text-contnet text-white">
                        <span className="land-text-contnet">
                          Terms & Conditions
                        </span>
                      </h1>
                      <p className="funeral-contnet mt-4">
                        Homepage / Terms & Conditions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container p-3 mx-auto">
            <div className="memorial-div">
              <div className="grid-imagecotnetmain">
                <div className="">
                  <div className="experincediff">
                    <p className="merorialtextinfo today-infoo termsand mb-12 ">
                      MI Terms and Conditions
                    </p>

                    <div>
                      <h1 className="termsandcondtinhead">
                        Agreement between User and memorialinformation.com
                      </h1>
                      <p className="established termsadncond">
                        Welcome to memorialinformation.com. The
                        memorialinformation.com website (the "Site") is
                        comprised of various web pages operated by Memorial
                        Information Inc ("Memorial Information or MI").
                        memorialinformation.com is offered to you conditioned on
                        your acceptance without modification of the terms,
                        conditions, and notices contained herein (the "Terms").
                        Your use of memorialinformation.com constitutes your
                        agreement to all such Terms. Please read these terms
                        carefully, and keep a copy of them for your reference.
                      </p>
                      <p className="established termsadncond">
                        memorialinformation.com is a Funeral Management, Project
                        Management, Property Disposition, Photo & Video Sharing,
                        Partner & Vendor Management, Employee Management,
                        Affiliate Marketing, & Any & All other related matters
                        Site.
                      </p>
                      <p className="established termsadncond">
                        Memorial Information has developed a highly secure,
                        comprehensive platform that allows members (platform
                        users) to digitally document, manage, organize,
                        collaboratively communicate, and plan events in one
                        convenient location. This solution-oriented platform
                        allows you and your loved-ones to have professional
                        estate planning services at your finger-tips without the
                        expensive price tag. Moreover, you can amend, add, edit,
                        delete, and upload documents as frequently as you need
                        through your user account. Users can share, post,
                        distribute, copy, publish, upload, download, and
                        advertise media including photos, videos, authorized
                        self-owned and self-publish music.
                      </p>{" "}
                      <p className="established termsadncond">
                        Memorial Information Membership levels include but are
                        not limited to and completely independent of one
                        another, the Legacy Builder Package, the Family
                        Transition Package, Family Legacy Plus, Data storage
                        upgrades, and any future products or services. The
                        initial payment provides a 1-year (2 years for the
                        Family Legacy Plus Package only) subscription to the
                        website and it features based upon the selected product
                        option. A $50 annual fee will be required for each
                        additional year of account activation due on the annual
                        (365 days) anniversary from the date your account was
                        activated. If no renewal payment is paid, users will
                        have 14 days to remedy the matter and make the renewal
                        payment before the account access is restricted. In the
                        event a user account is restricted, the user account
                        data will be stored for 90 days, after which all user
                        account data will be deleted including user's MI public
                        page & all other features.
                      </p>{" "}
                      <p className="established termsadncond">
                        Memorial Information has developed a nationwide network
                        of highly motivated and MI Certified industry
                        professionals who provide expert guidance and compassion
                        to families seeking to preserve a legacy. Partners,
                        Vendors, Affiliates, Employees, Consultants, Laborers, &
                        any other person or entity contracted to do business
                        with Memorial Information will abide by and be governed
                        by the specific contractual agreement entered into
                        between both parties.
                      </p>{" "}
                    </div>

                    <div>
                      <h1 className="termsandcondtinhead">Privacy</h1>
                      <p className="established termsadncond">
                        Your use of memorialinformation.com is subject to
                        Memorial Information or MI's Privacy Policy. Please
                        review our Privacy Policy, which also governs the Site
                        and informs users of our data collection practices.
                      </p>
                    </div>

                    <div>
                      <h1 className="termsandcondtinhead">
                        Electronic Communications
                      </h1>
                      <p className="established termsadncond">
                        Visiting memorialinformation.com or sending emails to
                        Memorial Information or MI constitutes electronic
                        communications. You consent to receive electronic
                        communications and you agree that all agreements,
                        notices, disclosures, and other communications that we
                        provide to you electronically, via email and on the
                        Site, satisfy any legal requirement that such
                        communications be in writing.
                      </p>
                    </div>
                    <div>
                      <h1 className="termsandcondtinhead">Your Account</h1>
                      <p className="established termsadncond">
                        If you use this site, you are responsible for
                        maintaining the confidentiality of your account and
                        password and for restricting access to your computer,
                        and you agree to accept responsibility for all
                        activities that occur under your account or password.
                        You may not assign or otherwise transfer your account to
                        any other person or entity. You acknowledge that
                        Memorial Information or MI is not responsible for third
                        party access to your account that results from theft or
                        misappropriation of your account. Memorial Information
                        or MI and its associates reserve the right to refuse or
                        cancel service, terminate accounts, or remove or edit
                        content in our sole discretion.
                      </p>
                    </div>
                    <div>
                      <h1 className="termsandcondtinhead">
                        Children Under Thirteen
                      </h1>
                      <p className="established termsadncond">
                        Memorial Information or MI collects personally
                        identifiable information from children under the age of
                        thirteen. Memorial Information or MI collects this
                        information for the following reason(s): 1. MI collects
                        information about children under 13 only if the adult MI
                        users provides said information. 2. MI collects
                        information about children under 13 only if the child is
                        the heir of a User or subject to be memorialized. 3. MI
                        collects information about children under 13 only if the
                        child is the beneficiary named by the adult user upon
                        the death of the adult user. 4. MI collects information
                        about children under 13 only if the child is a member of
                        a Family Tree or Family Reunion or other family communal
                        project and the child was added directly by the user.
                      </p>

                      <p className="established termsadncond">
                        We provide information about our personal data practices
                        for children on our home page and wherever we knowingly
                        collect personal data from children on our Web site. If
                        you are under the age of thirteen, you must ask your
                        parent or guardian for permission to use this website.
                        If you are a parent and you have questions regarding our
                        data collection practices, please contact us using the
                        information provided at the end of this Agreement. If
                        you are under 18, you may use memorialinformation.com
                        only with permission of a parent or guardian.
                      </p>
                    </div>
                    <div>
                      <h1 className="termsandcondtinhead">
                        Cancellation/Refund Policy
                      </h1>
                      <p className="established termsadncond">
                        Memorial Information provides a 7 day love-it or
                        leave-it policy for subscribed users. Upon purchasing
                        the Family Legacy, Family Transition, Family Legacy
                        Plus, MI Public Page, MI Renewal(s), MI Data Upgrade, or
                        any other product or services directly offer by and
                        through Memorial Information MI users have 7 (seven)
                        days to request a full refund. After 7 days to the hour,
                        ALL SALES ARE FINAL, and no refund request will be
                        honored. Payments made to and/or for Vendor, Partner, or
                        Affiliate services are NOT subject to this 7 day refund
                        period and will instead be governed by the agreement
                        between the user and the Vendor or Partner or Affiliate
                        and Memorial Information will bear NO responsibility for
                        refunding any fees paid to or on behalf of the Vendor or
                        Partner or Affiliate.
                      </p>

                      <p className="established termsadncond">
                        MI Partners subscribe to a 30-day (monthly) contract
                        that is non-refundable and subject to a 30 day written
                        cancellation notice.
                      </p>

                      <p className="established termsadncond">
                        MI Vendors and MI Affiliates may cancel their contract
                        with Memorial Information with a 30-day written notice
                        of cancellation. For the cancellation to be valid, ALL
                        contracted responsibilities MUST be completed, and no
                        additional products or services can still be due to the
                        MI User(s) prior to cancellation of the MI Vendor or MI
                        Affiliate agreement.
                      </p>
                    </div>

                    <div>
                      <h1 className="termsandcondtinhead">
                        Links to Third Party Sites/Third Party Services{" "}
                      </h1>
                      <p className="established termsadncond">
                        memorialinformation.com may contain links to other
                        websites ("Linked Sites"). The Linked Sites are not
                        under the control of Memorial Information or MI and
                        Memorial Information or MI is not responsible for the
                        contents of any Linked Site, including without
                        limitation any link contained in a Linked Site, or any
                        changes or updates to a Linked Site. Memorial
                        Information or MI is providing these links to you only
                        as a convenience, and the inclusion of any link does not
                        imply endorsement by Memorial Information or MI of the
                        site or any association with its operators.
                      </p>

                      <p className="established termsadncond">
                        Certain services made available via
                        memorialinformation.com are delivered by third party
                        sites and organizations. By using any product, service
                        or functionality originating from the
                        memorialinformation.com domain, you hereby acknowledge
                        and consent that Memorial Information or MI may share
                        such information and data with any third party with whom
                        Memorial Information or MI has a contractual
                        relationship to provide the requested product, service
                        or functionality on behalf of memorialinformation.com
                        users and customers.
                      </p>
                    </div>
                    <div>
                      <h1 className="termsandcondtinhead">
                        No Unlawful or Prohibited Use/Intellectual Property
                      </h1>
                      <p className="established termsadncond">
                        You are granted a non-exclusive, non-transferable,
                        revocable license to access and use
                        memorialinformation.com strictly in accordance with
                        these terms of use. As a condition of your use of the
                        Site, you warrant to Memorial Information or MI that you
                        will not use the Site for any purpose that is unlawful
                        or prohibited by these Terms. You may not use the Site
                        in any manner which could damage, disable, overburden,
                        or impair the Site or interfere with any other party's
                        use and enjoyment of the Site. You may not obtain or
                        attempt to obtain any materials or information through
                        any means not intentionally made available or provided
                        for through the Site. All content included as part of
                        the Service, such as text, graphics, logos, images, as
                        well as the compilation thereof, and any software used
                        on the Site, is the property of Memorial Information or
                        MI or its suppliers and protected by copyright and other
                        laws that protect intellectual property and proprietary
                        rights. You agree to observe and abide by all copyright
                        and other proprietary notices, legends or other
                        restrictions contained in any such content and will not
                        make any changes thereto
                      </p>

                      <p className="established termsadncond">
                        You will not modify, publish, transmit, reverse
                        engineer, participate in the transfer or sale, create
                        derivative works, or in any way exploit any of the
                        content, in whole or in part, found on the Site.
                        Memorial Information or MI content is not for resale.
                        Your use of the Site does not entitle you to make any
                        unauthorized use of any protected content, and in
                        particular you will not delete or alter any proprietary
                        rights or attribution notices in any content. You will
                        use protected content solely for your personal use, and
                        will make no other use of the content without the
                        express written permission of Memorial Information or MI
                        and the copyright owner. You agree that you do not
                        acquire any ownership rights in any protected content.
                        We do not grant you any licenses, express or implied, to
                        the intellectual property of Memorial Information or MI
                        or our licensors except as expressly authorized by these
                        Terms.
                      </p>
                    </div>
                    <div>
                      <h1 className="termsandcondtinhead">
                        Use of Communication Services
                      </h1>
                      <p className="established termsadncond">
                        The Site may contain bulletin board services, chat
                        areas, news groups, forums, communities, personal web
                        pages, calendars, and/or other message or communication
                        facilities designed to enable you to communicate with
                        the public at large or with a group (collectively,
                        "Communication Services"). You agree to use the
                        Communication Services only to post, send and receive
                        messages and material that are proper and related to the
                        particular Communication Service. By way of example, and
                        not as a limitation, you agree that when using a
                        Communication Service, you will not: defame, abuse,
                        harass, stalk, threaten or otherwise violate the legal
                        rights (such as rights of privacy and publicity) of
                        others; publish, post, upload, distribute or disseminate
                        any inappropriate, profane, defamatory, infringing,
                        obscene, indecent or unlawful topic, name, material or
                        information; upload files that contain software or other
                        material protected by intellectual property laws (or by
                        rights of privacy of publicity) unless you own or
                        control the rights thereto or have received all
                        necessary consents; upload files that contain viruses,
                        corrupted files, or any other similar software or
                        programs that may damage the operation of another's
                        computer; advertise or offer to sell or buy any goods or
                        services for any business purpose, unless such
                        Communication Service specifically allows such messages;
                        conduct or forward surveys, contests, pyramid schemes or
                        chain letters; download any file posted by another user
                        of a Communication Service that you know, or reasonably
                        should know, cannot be legally distributed in such
                        manner; falsify or delete any author attributions, legal
                        or other proper notices or proprietary designations or
                        labels of the origin or source of software or other
                        material contained
                      </p>

                      <p className="established termsadncond">
                        in a file that is uploaded; restrict or inhibit any
                        other user from using and enjoying the Communication
                        Services; violate any code of conduct or other
                        guidelines which may be applicable for any particular
                        Communication Service; harvest or otherwise collect
                        information about others, including e-mail addresses,
                        without their consent; violate any applicable laws or
                        regulations. Memorial Information or MI has no
                        obligation to monitor the Communication Services.
                        However, Memorial Information or MI reserves the right
                        to review materials posted to a Communication Service
                        and to remove any materials in its sole discretion.
                        Memorial Information or MI reserves the right to
                        terminate your access to any or all of the Communication
                        Services at any time without notice for any reason
                        whatsoever.
                      </p>

                      <p className="established termsadncond">
                        Memorial Information or MI reserves the right at all
                        times to disclose any information as necessary to
                        satisfy any applicable law, regulation, legal process or
                        governmental request, or to edit, refuse to post or to
                        remove any information or materials, in whole or in
                        part, in Memorial Information or MI's sole discretion.
                        Always use caution when giving out any personally
                        identifying information about yourself or your children
                        in any Communication Service. Memorial Information or MI
                        does not control or endorse the content, messages or
                        information found in any Communication Service and,
                        therefore, Memorial Information or MI specifically
                        disclaims any liability with regard to the Communication
                        Services and any actions resulting from your
                        participation in any Communication Service. Managers and
                        hosts are not authorized Memorial Information or MI
                        spokespersons, and their views do not necessarily
                        reflect those of Memorial Information or MI. Materials
                        uploaded to a Communication Service may be subject to
                        posted limitations on usage, reproduction and/or
                        dissemination. You are responsible for adhering to such
                        limitations if you upload the materials.
                      </p>
                    </div>
                    <div>
                      <h1 className="termsandcondtinhead">
                        Materials Provided to memorialinformation.com or Posted
                        on Any Memorial Information or MI Web Page{" "}
                      </h1>
                      <p className="established termsadncond">
                        Memorial Information or MI does not claim ownership of
                        the materials you provide to memorialinformation.com
                        (including feedback and suggestions) or post, upload,
                        input or submit to any Memorial Information or MI Site
                        or our associated services (collectively "Submissions").
                        However, by posting, uploading, inputting, providing or
                        submitting your Submission you are granting Memorial
                        Information or MI, our affiliated companies and
                        necessary sublicensees permission to use your Submission
                        in connection with the operation of their Internet
                        businesses including, without limitation, the rights to:
                        copy, distribute, transmit, publicly display, publicly
                        perform, reproduce, edit, translate and reformat your
                        Submission; and to publish your name in connection with
                        your Submission.
                      </p>

                      <p className="established termsadncond">
                        No compensation will be paid with respect to the use of
                        your Submission, as provided herein. Memorial
                        Information or MI is under no obligation to post or use
                        any Submission you may provide and may remove any
                        Submission at any time in Memorial Information or MI's
                        sole discretion. By posting, uploading, inputting,
                        providing or submitting your Submission you warrant and
                        represent that you own or otherwise control all of the
                        rights to your Submission as described in this section
                        including, without limitation, all the rights necessary
                        for you to provide, post, upload, input or submit the
                        Submissions.
                      </p>
                    </div>
                    <div>
                      <h1 className="termsandcondtinhead">
                        Third Party Accounts
                      </h1>
                      <p className="established termsadncond">
                        You will be able to connect your Memorial Information or
                        MI account to third party accounts. By connecting your
                        Memorial Information or MI account to your third party
                        account, you acknowledge and agree that you are
                        consenting to the continuous release of information
                        about you to others (in accordance with your privacy
                        settings on those third party sites). If you do not want
                        information about you to be shared in this manner, do
                        not use this feature.
                      </p>
                    </div>

                    <div>
                      <h1 className="termsandcondtinhead">
                        International Users
                      </h1>
                      <p className="established termsadncond">
                        The Service is controlled, operated and administered by
                        Memorial Information or MI from our offices within the
                        USA. If you access the Service from a location outside
                        the USA, you are responsible for compliance with all
                        local laws. You agree that you will not use the Memorial
                        Information or MI Content accessed through
                        memorialinformation.com in any country or in any manner
                        prohibited by any applicable laws, restrictions or
                        regulations.
                      </p>
                    </div>
                    <div>
                      <h1 className="termsandcondtinhead">Indemnification</h1>
                      <p className="established termsadncond">
                        You agree to indemnify, defend and hold harmless
                        Memorial Information or MI, its officers, directors,
                        employees, agents and third parties, for any losses,
                        costs, liabilities and expenses (including reasonable
                        attorney's fees) relating to or arising out of your use
                        of or inability to use the Site or services, any user
                        postings made by you, your violation of any terms of
                        this Agreement or your violation of any rights of a
                        third party, or your violation of any applicable laws,
                        rules or regulations. Memorial Information or MI
                        reserves the right, at its own cost, to assume the
                        exclusive defense and control of any matter otherwise
                        subject to indemnification by you, in which event you
                        will fully cooperate with Memorial Information or MI in
                        asserting any available defenses.
                      </p>
                    </div>
                    <div>
                      <h1 className="termsandcondtinhead">Arbitration</h1>
                      <p className="established termsadncond">
                        In the event the parties are not able to resolve any
                        dispute between them arising out of or concerning these
                        Terms and Conditions, or any provisions hereof, whether
                        in contract, tort, or otherwise at law or in equity for
                        damages or any other relief, then such dispute shall be
                        resolved only by final and binding arbitration pursuant
                        to the Federal Arbitration Act, conducted by a single
                        neutral arbitrator and administered by the American
                        Arbitration Association, or a similar arbitration
                        service selected by the parties, in a location mutually
                        agreed upon by the parties. The arbitrator's award shall
                        be final, and judgment may be entered upon it in any
                        court having jurisdiction. In the event that any legal
                        or equitable action, proceeding or arbitration arises
                        out of or concerns these Terms and Conditions, the
                        prevailing party shall be entitled to recover its costs
                        and reasonable attorney's fees. The parties agree to
                        arbitrate all disputes and claims in regards to these
                        Terms and Conditions or any disputes arising as a result
                        of these Terms and Conditions, whether directly or
                        indirectly, including Tort claims that are a result of
                        these Terms and Conditions. The parties agree that the
                        Federal Arbitration Act governs the interpretation and
                        enforcement of this provision. The entire dispute,
                        including the scope and enforceability of this
                        arbitration provision shall be determined by the
                        Arbitrator. This arbitration provision shall survive the
                        termination of these Terms and Conditions.
                      </p>
                    </div>

                    <div>
                      <h1 className="termsandcondtinhead">
                        Class Action Waiver
                      </h1>
                      <p className="established termsadncond">
                        Making the transition from hearing her familiar voice or
                        seeing his infectious smile to not having your loved-one
                      </p>

                      <p className="established termsadncond">
                        Any arbitration under these Terms and Conditions will
                        take place on an individual basis; class arbitrations
                        and class/representative/collective actions are not
                        permitted. THE PARTIES AGREE THAT A PARTY MAY BRING
                        CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL
                        CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                        PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE
                        PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY
                        GENERAL ACTION AGAINST THE OTHER. Further, unless both
                        you and Memorial Information or MI agree otherwise, the
                        arbitrator may not consolidate more than one person's
                        claims, and may not otherwise preside over any form of a
                        representative or class proceeding.{" "}
                      </p>
                    </div>
                    <div>
                      <h1 className="termsandcondtinhead">
                        Liability Disclaimer
                      </h1>
                      <p className="established termsadncond">
                        THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES
                        INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE
                        INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE
                        PERIODICALLY ADDED TO THE INFORMATION HEREIN. MEMORIAL
                        INFORMATION INC AND/OR ITS SUPPLIERS MAY MAKE
                        IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
                        MEMORIAL INFORMATION INC AND/OR ITS SUPPLIERS MAKE NO
                        REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
                        AVAILABILITY, TIMELINESS, AND ACCURACY OF THE
                        INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                        GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE
                        MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
                        INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                        GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR
                        CONDITION OF ANY KIND. MEMORIAL INFORMATION INC AND/OR
                        ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND
                        CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE,
                        PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL
                        IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                        NON-INFRINGEMENT.
                      </p>

                      <p className="established termsadncond">
                        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                        EVENT SHALL MEMORIAL INFORMATION INC AND/OR ITS
                        SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
                        INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY
                        DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION,
                        DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF
                        OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF
                        THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR
                        RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE
                        SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS,
                        SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE,
                        OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER
                        BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR
                        OTHERWISE, EVEN IF MEMORIAL INFORMATION INC OR ANY OF
                        ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF
                        DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW
                        THE EXCLUSION OR LIMITATION OF LIABILITY FOR
                        CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
                        LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED
                        WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS
                        OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
                        USING THE SITE.
                      </p>
                    </div>
                    <div>
                      <h1 className="termsandcondtinhead">
                        Termination/Access Restriction
                      </h1>
                      <p className="established termsadncond">
                        Memorial Information or MI reserves the right, in its
                        sole discretion, to terminate your access to the Site
                        and the related services or any portion thereof at any
                        time, without notice. To the maximum extent permitted by
                        law, this agreement is governed by the laws of the State
                        of Florida and you hereby consent to the exclusive
                        jurisdiction and venue of courts in Florida in all
                        disputes arising out of or relating to the use of the
                        Site. Use of the Site is unauthorized in any
                        jurisdiction that does not give effect to all provisions
                        of these Terms, including, without limitation, this
                        section. You agree that no joint venture, partnership,
                        employment, or agency relationship exists between you
                        and Memorial Information or MI as a result of this
                        agreement or use of the Site. Memorial Information or
                        MI's performance of this agreement is subject to
                        existing laws and legal process, and nothing contained
                        in this agreement is in derogation of Memorial
                        Information or MI's right to comply with governmental,
                        court and law enforcement requests or requirements
                        relating to your use of the Site or information provided
                        to or gathered by Memorial Information or MI with
                        respect to such use. If any part of this agreement is
                        determined to be invalid or unenforceable pursuant to
                        applicable law including, but not limited to, the
                        warranty disclaimers and liability limitations set forth
                        above, then the invalid or unenforceable provision will
                        be deemed superseded by a valid, enforceable provision
                        that most closely matches the intent of the original
                        provision and the remainder of the agreement shall
                        continue in effect.
                      </p>

                      <p className="established termsadncond">
                        Unless otherwise specified herein, this agreement
                        constitutes the entire agreement between the user and
                        Memorial Information or MI with respect to the Site and
                        it supersedes all prior or contemporaneous
                        communications and proposals, whether electronic, oral
                        or written, between the user and Memorial Information or
                        MI with respect to the Site. A printed version of this
                        agreement and of any notice given in electronic form
                        shall be admissible in judicial or administrative
                        proceedings based upon or relating to this agreement to
                        the same extent and subject to the same conditions as
                        other business documents and records originally
                        generated and maintained in printed form. It is the
                        express wish to the parties that this agreement and all
                        related documents be written in English.
                      </p>
                    </div>
                    <div>
                      <h1 className="termsandcondtinhead">Changes to Terms</h1>
                      <p className="established termsadncond">
                        Memorial Information or MI reserves the right, in its
                        sole discretion, to change the Terms under which
                        memorialinformation.com is offered. The most current
                        version of the Terms will supersede all previous
                        versions. Memorial Information or MI encourages you to
                        periodically review the Terms to stay informed of our
                        updates.
                      </p>
                    </div>

                    <div>
                      <h1 className="termsandcondtinhead">Contact Us</h1>
                      <p className="established termsadncond">
                        Memorial Information or MI welcomes your questions or
                        comments regarding the Terms:
                      </p>

                      <p className="established termsadncond">
                        Memorial Information Inc ,<br />
                        3270 S HWY A1A Ste 106 <br />
                        Melbourne Beach, Florida 32951 <br />
                        Email Address: <br />
                        services@memorialinformation.com <br />
                        Telephone number: <br />
                        941-334-2991 <br />
                        Effective as of December 01, 2023 <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms_Condtion;
