export const ADD_FILE_STATUS_REQUEST = "ADD_FILE_STATUS_REQUEST";
export const ADD_FILE_STATUS_SUCCESS = "ADD_FILE_STATUS_SUCCESS";
export const ADD_FILE_STATUS_FAIL = "ADD_FILE_STATUS_FAIL";

export const FILE_GET_SHOW_REQUEST = "FILE_GET_SHOW_REQUEST";
export const FILE_GET_SHOW_SUCCESS = "FILE_GET_SHOW_SUCCESS";
export const FILE_GET_SHOW_FAIL = "FILE_GET_SHOW_FAIL";

export const STATE_CHANGE_USER_REQUEST = "STATE_CHANGE_USER_REQUEST";
export const STATE_CHANGE_USER_SUCCESS = "STATE_CHANGE_USER_SUCCESS";
export const STATE_CHANGE_USER_FAIL = "STATE_CHANGE_USER_FAIL";

export const FILE_GET_SHOW_DATA_BYID_REQUEST =
  "FILE_GET_SHOW_DATA_BYID_REQUEST";
export const FILE_GET_SHOW_DATA_BYID_SUCCESS =
  "FILE_GET_SHOW_DATA_BYID_SUCCESS";
export const FILE_GET_SHOW_DATA_BYID_FAIL = "FILE_GET_SHOW_DATA_BYID_FAIL";

export const DELETE_FILE_STATUS_REQUEST = "DELETE_FILE_STATUS_REQUEST";
export const DELETE_FILE_STATUS_SUCCESS = "DELETE_FILE_STATUS_SUCCESS";
export const DELETE_FILE_STATUS_FAIL = "DELETE_FILE_STATUS_FAIL";

// carreers

export const USER_SEND_JOB_INQUIRY_REQUEST = "USER_SEND_JOB_INQUIRY_REQUEST";
export const USER_SEND_JOB_INQUIRY_SUCCESS = "USER_SEND_JOB_INQUIRY_SUCCESS";
export const USER_SEND_JOB_INQUIRY_FAIL = "USER_SEND_JOB_INQUIRY_FAIL";

export const GET_USER_SEND_JOB_INQUIRY_REQUEST =
  "GET_USER_SEND_JOB_INQUIRY_REQUEST";
export const GET_USER_SEND_JOB_INQUIRY_SUCCESS =
  "GET_USER_SEND_JOB_INQUIRY_SUCCESS";
export const GET_USER_SEND_JOB_INQUIRY_FAIL = "GET_USER_SEND_JOB_INQUIRY_FAIL";

export const DELETE_USER_SEND_JOB_INQUIRY_REQUEST =
  "DELETE_USER_SEND_JOB_INQUIRY_REQUEST";
export const DELETE_USER_SEND_JOB_INQUIRY_SUCCESS =
  "DELETE_USER_SEND_JOB_INQUIRY_SUCCESS";
export const DELETE_USER_SEND_JOB_INQUIRY_FAIL =
  "DELETE_USER_SEND_JOB_INQUIRY_FAIL";

// job

export const ADMIN_ADD_JOB_REQUEST = "ADMIN_ADD_JOB_REQUEST";
export const ADMIN_ADD_JOB_SUCCESS = "ADMIN_ADD_JOB_SUCCESS";
export const ADMIN_ADD_JOB_FAIL = "ADMIN_ADD_JOB_FAIL";

export const ADMIN_GET_JOB_REQUEST = "ADMIN_GET_JOB_REQUEST";
export const ADMIN_GET_JOB_SUCCESS = "ADMIN_GET_JOB_SUCCESS";
export const ADMIN_GET_JOB_FAIL = "ADMIN_GET_JOB_FAIL";

export const ADMIN_DELETE_JOB_REQUEST = "ADMIN_DELETE_JOB_REQUEST";
export const ADMIN_DELETE_JOB_SUCCESS = "ADMIN_DELETE_JOB_SUCCESS";
export const ADMIN_DELETE_JOB_FAIL = "ADMIN_DELETE_JOB_FAIL";

export const ADMIN_GET_BYID_JOB_REQUEST = "ADMIN_GET_BYID_JOB_REQUEST";
export const ADMIN_GET_BYID_SUCCESS = "ADMIN_GET_BYID_SUCCESS";
export const ADMIN_GET_BYID_FAIL = "ADMIN_GET_BYID_FAIL";

export const ADMIN_UPDATE_JOB_REQUEST = "ADMIN_UPDATE_JOB_REQUEST";
export const ADMIN_UPDATE_JOB_SUCCESS = "ADMIN_UPDATE_JOB_SUCCESS";
export const ADMIN_UPDATE_JOB_FAIL = "ADMIN_UPDATE_JOB_FAIL";

export const FUNERAL_PERSON_DETAIL_ADD_REQUEST =
  "FUNERAL_PERSON_DETAIL_ADD_REQUEST";
export const FUNERAL_PERSON_DETAIL_ADD_SUCCESS =
  "FUNERAL_PERSON_DETAIL_ADD_SUCCESS";
export const FUNERAL_PERSON_DETAIL_ADD_FAIL = "FUNERAL_PERSON_DETAIL_ADD_FAIL";

export const FUNERAL_PERSON_DETAIL_GET_REQUEST =
  "FUNERAL_PERSON_DETAIL_GET_REQUEST";
export const FUNERAL_PERSON_DETAIL_GET_SUCCESS =
  "FUNERAL_PERSON_DETAIL_GET_SUCCESS";
export const FUNERAL_PERSON_DETAIL_GET_FAIL = "FUNERAL_PERSON_DETAIL_GET_FAIL";

export const FUNERAL_PERSON_DETAIL_DELETE_REQUEST =
  "FUNERAL_PERSON_DETAIL_DELETE_REQUEST";
export const FUNERAL_PERSON_DETAIL_DELETE_SUCCESS =
  "FUNERAL_PERSON_DETAIL_DELETE_SUCCESS";
export const FUNERAL_PERSON_DETAIL_DELETE_FAIL =
  "FUNERAL_PERSON_DETAIL_DELETE_FAIL";

export const FUNERAL_PERSON_DETAIL_UPDATE_REQUEST =
  "FUNERAL_PERSON_DETAIL_UPDATE_REQUEST";
export const FUNERAL_PERSON_DETAIL_UPDATE_SUCCESS =
  "FUNERAL_PERSON_DETAIL_UPDATE_SUCCESS";
export const FUNERAL_PERSON_DETAIL_UPDATE_FAIL =
  "FUNERAL_PERSON_DETAIL_UPDATE_FAIL";

export const USER_APPLY_JOB_REQUEST = "USER_APPLY_JOB_REQUEST";
export const USER_APPLY_JOB_SUCCESS = "USER_APPLY_JOB_SUCCESS";
export const USER_APPLY_JOB_FAIL = "USER_APPLY_JOB_FAIL";

export const APPLY_JOB_SEE_DETAILS_REQUEST = "APPLY_JOB_SEE_DETAILS_REQUEST";
export const APPLY_JOB_SEE_DETAILS_SUCCESS = "APPLY_JOB_SEE_DETAILS_SUCCESS";
export const APPLY_JOB_SEE_DETAILS_FAIL = "APPLY_JOB_SEE_DETAILS_FAIL";

export const APPLIED_JOB_SHOWADMIN_REQUEST = "APPLIED_JOB_SHOWADMIN_REQUEST";
export const APPLIED_JOB_SHOWADMIN_SUCCESS = "APPLIED_JOB_SHOWADMIN_SUCCESS";
export const APPLIED_JOB_SHOWADMIN_FAIL = "APPLIED_JOB_SHOWADMIN_FAIL";

export const APPLIED_JOB_SHOWADMIN_BYID_DETAIL_REQUEST =
  "APPLIED_JOB_SHOWADMIN_BYID_DETAIL_REQUEST";
export const APPLIED_JOB_SHOWADMIN_BYID_DETAIL_SUCCESS =
  "APPLIED_JOB_SHOWADMIN_BYID_DETAIL_SUCCESS";
export const APPLIED_JOB_SHOWADMIN_BYID_DETAIL_FAIL =
  "APPLIED_JOB_SHOWADMIN_BYID_DETAIL_FAIL";

export const STATUS_UPDATE_REQUEST = "STATUS_UPDATE_REQUEST";
export const STATUS_UPDATE_SUCCESS = "STATUS_UPDATE_SUCCESS";
export const STATUS_UPDATE_FAIL = "STATUS_UPDATE_FAIL";

export const STATUS_UPDATE_GET_REQUEST = "STATUS_UPDATE_GET_REQUEST";
export const STATUS_UPDATE_GET_SUCCESS = "STATUS_UPDATE_GET_SUCCESS";
export const STATUS_UPDATE_GET_FAIL = "STATUS_UPDATE_GET_FAIL";


